export const sections = [
	{
		id: 'cover',
		label: 'Coal vs. Natural Gas',

		style: [],
		theme: '',

		title: {
			headline: 'Coal vs. Natural Gas',
			subheadline: 'What Is Their Relative Short Term Climate Impact?',
		},

		hero: {
			type: 'Cover',
		},

		scenes: [
			{
				style: [],
				theme: null,
				trigger: null,

				hero: {
					type: 'custom',
					name: 'Bar',
					settings: {
						showMethane: false,
					},
				},

				steps: [
					{
						type: 'text',

						style: [],
						theme: null,

						trigger: null,

						content: [
							"<p class='text'>When gas is burned, it releases 50 percent less carbon dioxide into the atmosphere than coal.</p>",

							"<p class='text'>Therefore, most people assume it has <em>half the climate impact</em> as coal.</p>",
						],
					},
				],
			},

			{
				style: [],
				theme: null,
				trigger: null,

				hero: {
					type: 'custom',
					name: 'Bar',
					settings: {
						showMethane: true,
					},
				},

				steps: [
					{
						type: 'text',

						style: [],
						theme: null,

						trigger: null,

						content: [
							"<p class='text'>But this doesn’t account for <em>methane leaks.</em></p>",
						],
					},
				],
			},

			{
				style: [],
				theme: null,
				trigger: '',

				hero: {
					type: 'custom',
					name: 'Gases',
				},

				steps: [
					{
						type: 'text',

						style: [],
						theme: null,

						trigger: null,

						content: [
							"<h2 class='text'>Methane is a powerful climate pollutant</h2>",

							"<p class='text'>Over a 20-year period, it traps over <em>82.5 times</em> more heat than carbon dioxide (CO<sub>2</sub>).</p>",
						],
					},
				],
			},

			{
				style: [],
				theme: null,
				trigger: '',

				hero: {
					type: 'custom',
					name: 'Malfunctioning',
				},

				steps: [
					{
						type: 'text',

						style: [],
						theme: null,

						trigger: null,

						content: [
							"<p class='text'>Methane leakage is caused by the <em>malfunction of compressors, pumps, and valves...</em></p>",
						],
					},
				],
			},

			{
				style: [],
				theme: null,
				trigger: '',

				hero: {
					type: 'custom',
					name: 'Intentional',
				},

				steps: [
					{
						type: 'text',

						style: [],
						theme: null,

						trigger: null,

						content: [
							"<p class='text'>...and by <em>intentional releases (venting and flaring).</em> Leakage is measured as a percentage of total natural gas produced.</p>",
						],
					},
				],
			},

			{
				style: [],
				theme: null,
				trigger: '',

				hero: {
					type: 'custom',
					name: 'Chart',
				},

				steps: [
					{
						type: 'text',

						style: [],
						theme: null,

						trigger: null,

						content: [
							"<p class='text'>When methane leakage from gas systems is included, the climate benefit of gas compared to coal flips. Methane leakage as low as 0.2% can bring gas' net GHG emissions on par with coal.</p>",
						],
					},

					/*
					{
						type: 'text',

						style: [],
						theme: null,

						trigger: null,

						content: [
							"<p class='text'>Natural gas has a 25% lower climate impact than coal at this leakage rate — <em>not 50%.</em></p>",
						],
					},
					*/

					{
						type: 'text',

						style: [],
						theme: null,

						trigger: null,

						content: [
							"<p class='text'>However, studies are finding that the EPA estimate of methane leakage is <em>too low</em> because it uses generic leakage factors and incomplete measurements from ground based equipment.</p>",
						],
					},
				],
			},

			{
				style: [],
				theme: null,
				trigger: '',

				hero: {
					type: 'image',
					classes: ['scene__hero__image--full'],
					key: './client/media/images/leak_key.svg',
					source: 'Image of a methane plume detected via Carbon Mapper aerial survey',
					url: './client/media/images/leak.jpeg',
				},

				steps: [
					{
						type: 'text',

						style: [],
						theme: null,

						trigger: null,

						content: [
							'<h2>Studies find concerning levels of leakage in the United States</h2>',

							"<p class='text'>Technology mounted on airplanes and satellites has provided more accurate methane emissions measurement at larger scale.</p>",
						],
					},
				],
			},

			{
				style: [],
				theme: null,
				trigger: '',

				hero: {
					type: 'custom',
					name: 'Chart',
					settings: {
						showStudies: true,
					},
				},

				steps: [
					{
						type: 'text',

						style: [],
						theme: null,

						trigger: null,

						content: [
							"<p class='text'>Over the past 5 years, several studies using this technology have been published. They reported methane leakage rates from <em>1.2 to 11 percent.</em></p>",

							"<p class='text'>Aerial measurements can vary widely because of super emitters—big leaks that disproportionately contribute to regional emissions.</p>",
						],
					},
					{
						type: 'text',

						style: [],
						theme: null,

						trigger: null,

						content: [
							"<p class='text'>This indicates that natural gas may not have any climate advantage over coal with the current rate of methane leakage.<p>",
						],
					},
				],
			},

			{
				style: [],
				theme: null,
				trigger: '',

				hero: {
					type: 'image',
					classes: ['scene__hero__image--full'],
					source: 'Photo by Jeremy Bishop on Unsplash',
					url: './client/media/images/city.jpeg',
				},

				steps: [
					{
						type: 'text',

						style: [],
						theme: null,

						trigger: null,

						content: [
							"<h2 class='text'>Natural gas can achieve 50 percent lower climate impact if virtually all methane leakage is controlled.</h2>",

							"<p class='text'>Leaky gas is even worse than coal. Stopping gas from leaking can be done profitably by cutting waste and preventing it from escaping into the atmosphere.</p>",

							"<p class='text'>Reducing US methane leakage from gas systems to minimum levels would be equivalent to taking <em>all US cars off the road for a year</em>.</p>"

						],
					},
				],
			},
		],
	},
];
