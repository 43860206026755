<script>
	import { onMount } from 'svelte';

	let element;
	let width = window.innerWidth;
	let height = window.innerHeight;

	export let data;
	export let settings = {
		showStudies: true,
	};

	const baseline = [
		{
			label: 'End Use Combustion (CO<sub>2</sub> Only)',
			leakage: '0.0%',
			comparison: -50,
		},
		{
			label: 'Gordon et al. (All pollutants)',
			leakage: '0.2%',
			comparison: 0,
		}
	];

	const studies = [
		{
			label: 'EPA Estimate',
			leakage: '1.4%',
			comparison: 23,
		},
		{
			label: 'Alverez et al.',
			leakage: '2.0%',
			comparison: 35,
			url: 'https://www.science.org/doi/abs/10.1126/science.aar7204',
		},
		{
			label: 'Zhang et al.',
			leakage: '3.3%',
			comparison: 61,
			url: 'https://www.science.org/doi/full/10.1126/sciadv.aaz5120',
		},
		{
			label: 'Schneising et al.',
			leakage: '1.2%–5.9%',
			comparison: 66,
			url: 'https://acp.copernicus.org/articles/20/9169/2020/',
		},
		{
			label: 'Irakulis-Loitxate et al.',
			leakage: '5.0%',
			comparison: 95,
			url: 'https://www.science.org/doi/full/10.1126/sciadv.abf4507',
		},
		{
			label: 'Lin et al.',
			leakage: '6.0%–8.0%',
			comparison: 135,
			url: 'https://www.nature.com/articles/s41598-021-01721-5',
		},
		{
			label: 'Chen et al.',
			leakage: '9.4%',
			comparison: 183,
			url: 'https://pubs.acs.org/doi/10.1021/acs.est.1c06458',
		},
		{
			label: 'Omara et al.',
			leakage: '11.0%',
			comparison: 215,
			url: 'https://www.nature.com/articles/s41467-022-29709-3',
		},
	];

	const getOffset = (value) => {
		if (value > 0) {
			return (150 / 300) * 100 - Math.abs(value) / 3;
		}

		return (150 / 300) * 100;
	};

	onMount(() => {});
</script>

<svelte:window bind:innerHeight={height} bind:innerWidth={width} />

<div class="chart">
	<h2>Climate Impact: US Methane Studies</h2>

	<div>
		<div class="row row--header">
			<div class="row__label" />
			<div class="row__leakage">Leakage Rate</div>
			<div class="row__chart__headers">
				<div
					class="row__chart__header row__chart__header--hide-on-mobile"
					style="left: calc(25% - 60px)"
				>
					Better
				</div>
				<div class="row__chart__header" style="left: calc(50% - 60px)">
					Same as Coal
				</div>
				<div
					class="row__chart__header row__chart__header--hide-on-mobile"
					style="left: calc(75% - 60px)"
				>
					Worse
				</div>
			</div>
		</div>

		<div class="" style="position: relative;">
			<div class="chart__divider" style=" " />

			<div>
				{#each baseline as row}
					<div
						class="row row--estimates row--{row.comparison < 0
							? 'negative'
							: 'positive'}"
					>
						<div class="row__info">
							<div class="row__label">{@html row.label}</div>
							<div class="row__leakage">{row.leakage}</div>
						</div>
						<div class="row__chart">
							<div
								class="row__chart__bar"
								style="width: {Math.abs(row.comparison) /
									3}%; left: {getOffset(row.comparison)}%;"
							>
								<div class="row__chart__bar__label">
									{row.comparison}%
								</div>
							</div>
						</div>
					</div>
				{/each}
			</div>
		</div>

		<div
			class={settings.showStudies ? '' : 'hidden'}
			style="position: relative;"
		>
			<div class="chart__divider" style=" " />

			<h3 class="chart__section">Studies</h3>

			{#each studies as row}
				<div
					class="row row--study row--{row.comparison < 0
						? 'negative'
						: 'positive'}"
				>
					<div class="row__info">
						<a href={row.url} class="row__label">{row.label}</a>
						<div class="row__leakage">{row.leakage}</div>
					</div>

					<div class="row__chart">
						<div
							class="row__chart__bar"
							style="width: {Math.abs(row.comparison) /
								3}%; left: {getOffset(row.comparison)}%;"
						>
							<div class="row__chart__bar__label">
								{row.comparison > 0
									? '+'
									: ''}{row.comparison}%
							</div>
						</div>
					</div>
				</div>
			{/each}
		</div>
	</div>
</div>

<style>
	.chart {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		display: flex;
		overflow-x: hidden;
		overflow-y: auto;
		flex-direction: column;
		justify-content: center;

		max-width: 1280px;
		margin: auto;
		padding: 0 60px;

		font-size: 18px;
	}

	.chart h2 {
		text-align: center;

		font-size: 36px;
		line-height: 1;
	}

	.chart__divider {
		position: absolute;
		z-index: 999;
		top: 0;
		left: calc(320px + (100% - 320px) * 0.5 - 8px);

		height: 100%;

		border-right: 2px dotted #fff;
	}

	.chart__section {
		margin: 0;
		padding: 16px 0;

		letter-spacing: 0.05em;
		text-transform: uppercase;

		color: rgba(144, 144, 144, 1);

		font-size: 0.8em;
		font-weight: 600;
	}

	.row--header {
		letter-spacing: 0.05em;
		text-transform: uppercase;

		color: rgba(144, 144, 144, 1);

		font-size: 0.65em;
		font-weight: 600;
	}

	.row__chart__headers {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: space-between;

		width: 100%;
		height: 60px;
	}

	.row__chart__header {
		position: absolute;

		width: 120px;
		padding: 0 4px;

		text-align: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	.row {
		display: flex;
		align-items: center;
		
		padding: 0 16px 0 0;
	}

	.row--estimates {
		margin: 0 0 1.5rem 0;
		display: flex;
		align-items: center;
	}

	.row--estimates:first-child {
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		padding-top: .5rem;
	}

	.row--estimates:last-child {
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		padding-bottom: .5rem;
	}

	.row--study {
		margin: 0 0 18px 0;
	}

	.row--study .row__label {
		cursor: pointer;
	}

	.row__chart {
		position: relative;
	}

	.row__info {
		display: flex;
		align-items: center;
	}

	.row__label {
		flex-grow: 0;
		flex-shrink: 0;

		width: 200px;
		padding: 0 12px 0 0;
		/* text-align: right; */

		font-weight: 700;
	}


	.row__leakage {
		flex-grow: 0;
		flex-shrink: 0;

		width: 120px;

		/* white-space: nowrap; */
	}

	.row__chart {
		position: relative;

		width: 100%;
		height: 24px;
		padding: 0 6px;

		border-radius: 8px;
		background: rgba(23, 56, 75, 1);
	}

	.row__chart__bar {
		position: absolute;

		height: 100%;
		border-radius: 0 6px 6px 0;
		
		background: var(--methane);
	}

	.row--negative .row__chart__bar {
		border-radius: 6px 0 0 6px;
		background-color: var(--secondary);
		transform: translate3d(-100%, 0, 0);
		background: rgba(64, 209, 241, 1);
	}

	.row__chart__bar__label {
		position: absolute;

		display: flex;
		align-items: center;

		/* width: 60px; */
		height: 100%;
		padding: 0 6px;

		/* color: #000; */
		color: #fff;

		font-size: 0.8em;
	}

	.row--positive .row__chart__bar__label {
		/* right: 0; */
		transform: translate3d(-100%, 0, 0);
		justify-content: flex-end;
	}

	.row--positive .row__chart__bar {
		transform: translate3d(100%, 0, 0);
	}

	.row--negative .row__chart__bar__label {
		transform: translate3d(-100%, 0, 0);
		justify-content: flex-start;
	}

	.hidden {
		visibility: hidden;
	}

	.source {
		font-family: 'Lora', sans-serif;
		font-style: italic;
	}

	@media screen and (max-width: 720px) {
		.chart {
			padding: 24px;
		}

		.chart__divider {
			left: calc(200px + (100% - 200px) * 0.5 - 8px);
		}

		.row__info {
			font-size: 0.8em;
		}

		.row__label {
			width: 120px;
		}

		.row__leakage {
			width: 80px;
		}

		.row {
			/* display: block; */
		}

		.row--study {
			margin: 0 0 12px 0;
		}

		.row__label {
			/* margin: 0 0 8px 0; */
		}

		.row__chart__bar__label {
			font-size: 0.6em;
		}

		.row__chart {
			height: 8px;
		}

		.row__chart__header--hide-on-mobile {
			display: none;
		}


		.row--positive .row__chart__bar__label {
			/* right: auto; */
			/* left: -60px; */
			/* right: 0; */
			transform: translate3d(-100%, 0, 0);

			justify-content: flex-end;
		}

		.row--negative .row__chart__bar__label {
			/* right: -60px; */
			transform: translate3d(-100%, 0, 0);
			/* left: auto; */
			left: 0;

			justify-content: flex-start;
		}
	}
</style>
