const scale = (v) => Math.sqrt(v) * 12;

export const renderStyles = {
	methane: {
		size: scale(1 * 24),
		options: {
			fillStyle: '#FF833A',
		},
	},
	carbon: {
		size: scale(1),
		options: {
			fillStyle: '#B8B8B8',
		},
	},
};