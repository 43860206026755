<script>
	import { onMount } from 'svelte';

	import gravity from './Leaks.js';

	import { renderStyles } from './shared.js';

	let element;
	let width = window.innerWidth;
	let height = window.innerHeight;

	onMount(() => {
		gravity(element, {
			width,
			height,

			emitters: [
				() => {
					const type = Math.random() < 0.5 ? 'carbon' : 'methane';

					const sizes = {
						carbon: 8,
						methane: 24,
					};

					const x = Math.random() * window.innerWidth;
					const y = window.innerHeight + 120;

					const radius = sizes[type] * 0.5;

					const options = {
						render: renderStyles[type].options,
					};

					return [x, y, radius, options];
				},
			],

			delay: 100,
			max: 250,
		});
	});
</script>

<svelte:window bind:innerHeight={height} bind:innerWidth={width} />

<div class="cover" bind:this={element} />

<style>
	.cover {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
</style>
