<script>
	import { onMount } from 'svelte';

	let element;
	let width = window.innerWidth;
	let height = window.innerHeight;

	export let data = null;
	export let settings = {};

	onMount(() => {});
</script>

<svelte:window bind:innerHeight={height} bind:innerWidth={width} />

<div class="figure">
	<div class="illustrations" style="display: flex">
		<div class="illustration">
			<img
				src="./client/media/images/illustrations/Bar_Column_1.svg"
				alt=""
			/>
			<div class="figure__label">Coal</div>
		</div>
		<div class="illustration">
			<img
				src="./client/media/images/illustrations/{!settings.showMethane
					? 'Bar_Column_2'
					: 'Bar_Column_3'}.svg"
				alt=""
			/>
			<div class="figure__label">Natural Gas</div>
		</div>
	</div>

	<!-- <div
		class="figure__background"
		style="background-image: url('./client/media/images/intentional.svg')"
	/> -->
</div>

<style>
	.figure {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		pointer-events: none;
	}

	.figure__header {
		position: absolute;

		width: 100%;
		padding: 60px;
	}

	.figure h2 {
		max-width: 1280px;
		margin: auto;
		padding: 60px;
	}

	.figure__bubbles {
		position: absolute;

		overflow: hidden;

		width: 100%;
		height: 100%;
	}

	.illustrations {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 100%;
		padding: 0 60px;
	}

	.illustration {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: flex-end;
		/* width: calc(100% / 3); */
		/* height: 240px; */

		padding: 0 12px;
	}

	.illustration img {
		width: 100%;
		max-width: 240px;
		margin: 0 0 24px 0;

		object-fit: contain;
	}

	.illustration__label {
		text-transform: uppercase;
	}

	.figure__background {
		position: absolute;

		overflow: hidden;

		width: 100%;
		height: 100%;

		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	@media screen and (max-width: 720px) {
		.illustrations {
			padding: 0 24px;
		}
		
		.illustration {
			width: 50%;
		}
	}
</style>
