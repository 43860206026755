<script>
	import * as d3 from 'd3';

	import { importData } from '../../js/import.js';

	// import custom components here

	import Bar from '../custom/Bar.svelte';
	import Chart from '../custom/Chart.svelte';
	import Cover from '../custom/Cover.svelte';
	import Gases from '../custom/Gases.svelte';
	import Intentional from '../custom/Intentional.svelte';
	import Malfunctioning from '../custom/Malfunctioning.svelte';

	import { onMount } from 'svelte';
	import { content, selected, trigger } from '../../stores.js';

	const components = {
		Bar,
		Chart,
		Cover,
		Gases,
		Intentional,
		Malfunctioning,
	};

	export let scene;
	let data;
	let embed;

	$: active = $selected.scene === scene.index;
	$: settings = scene.hero && scene.hero.settings ? scene.hero.settings : {};

	if (scene.hero && scene.hero.data && Array.isArray(scene.hero.data)) {
		importData(scene.hero.data).then((combined) => {
			data = combined;
		});
	}

	onMount(() => {
		trigger.subscribe((trigger) => {
			if (!trigger || trigger.scene !== scene.index) return;

			if (embed && embed.contentWindow) {
				embed.contentWindow.postMessage(trigger, '*');
			}
		});
	});
</script>

{#if scene.hero}
	<div class="scene__hero {active ? 'active' : 'inactive'}">
		<div class="scene__hero__graphic">
			{#if scene.hero.type === 'custom'}
				{#if components[scene.hero.name]}
					<svelte:component
						this={components[scene.hero.name]}
						{data}
						{settings}
					/>
				{/if}
			{/if}

			{#if scene.hero.type === 'image'}
				<div
					class="scene__hero__image {(scene.hero.classes || []).join(
						' '
					)}"
					style="background-image:url({scene.hero.url})"
				/>

				{#if scene.hero.key}
					<div class="scene__hero__key">
						<img src={scene.hero.key} alt="" />
					</div>
				{/if}

				{#if scene.hero.source}
					<div class="scene__hero__source">
						{scene.hero.source}
					</div>
				{/if}
			{/if}
		</div>
	</div>
{/if}

<style>
	.scene__hero__key,
	.scene__hero__source {
		position: absolute;

		font-family: 'Lora', sans-serif;
		font-style: italic;
	}

	.scene__hero__key {
		right: 24px;
		bottom: 60px;
	}

	.scene__hero__source {
		bottom: 24px;
		left: 24px;
	}
</style>
