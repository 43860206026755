<script>
	import { onMount } from 'svelte';

	let element;
	let width = window.innerWidth;
	let height = window.innerHeight;

	export let data = null;
	export let settings = {};

	$: screenScale = Math.min(width / 72, 20);

	const scale = (v) => Math.sqrt(v / Math.PI) * 3;

	$: carbonRadius = scale(1) * screenScale;
	$: methaneRadius = scale(82.5) * screenScale;

	onMount(() => {});
</script>

<svelte:window bind:innerHeight={height} bind:innerWidth={width} />

<div class="figure">
	<div class="illustrations" style="display: flex">
		<div class="illustration">
			<svg
				width={carbonRadius * 2}
				height={carbonRadius * 2}
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					fill="var(--gray-100)"
					cx={carbonRadius}
					cy={carbonRadius}
					r={carbonRadius}
				/>
			</svg>

			<div class="figure__label">Carbon Dioxide (CO<sub>2</sub>)</div>
		</div>
		<div class="illustration">
			<svg
				width={methaneRadius * 2}
				height={methaneRadius * 2}
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					fill="var(--methane)"
					cx={methaneRadius}
					cy={methaneRadius}
					r={methaneRadius}
				/>
			</svg>

			<div class="figure__label">Methane (CH<sub>4</sub>)</div>
		</div>
	</div>

	<!-- <div
		class="figure__background"
		style="background-image: url('./client/media/images/intentional.svg')"
	/> -->
</div>

<style>
	.figure {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		display: flex;
		align-items: center;

		pointer-events: none;
	}

	/*

	.figure__header {
		position: absolute;

		width: 100%;
		padding: 60px;
	}

	.figure h2 {
		max-width: 1280px;
		margin: auto;
		padding: 60px;
	}

	*/

	.figure__bubbles {
		position: absolute;

		overflow: hidden;

		width: 100%;
		height: 100%;
	}

	.illustrations {
		display: flex;
		justify-content: center;

		width: 100%;
		padding: 0 60px;
	}

	.illustration {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: flex-end;

		padding: 0 12px;
	}

	.illustration svg {
		margin: 0 0 24px 0;
	}

	.illustration__label {
		text-transform: uppercase;
	}

	.figure__background {
		position: absolute;

		overflow: hidden;

		width: 100%;
		height: 100%;

		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	@media screen and (max-width: 720px) {
		.illustrations {
			padding: 0 24px;
		}

		.illustration {
			width: 50%;
		}

		.figure__label {
			min-height: 60px;
		}
	}
</style>
