import Matter from 'matter-js';
// import { createWalls } from './shared';

// https://stackoverflow.com/questions/31938109/matter-js-gravity-point

const scale = (v) => Math.sqrt(v) * 12;

const renderStyle = {
	methane: {
		size: scale(1 * 24),
		options: {
			fillStyle: '#FF833A',
		},
	},
	carbon: {
		size: scale(1),
		options: {
			fillStyle: '#B8B8B8',
		},
	},
};

export default function gravity(element, options = {}) {
	const w = window.innerWidth;
	const h = window.innerHeight;

	const delay = options.delay || 500;
	const emitters = options.emitters || [];
	const max = options.max || 50;

	/*
	const emitters = [
		() => {
			const type = Math.random() < 0.5 ? 'carbon' : 'methane';

			const x = Math.random() * window.innerWidth;
			const y = window.innerHeight + 120;

			const radius = Math.random() * 36 + 6;

			const options = {
				render: renderStyle[type].options
			}

			return [x, y, radius, options];
		},
	];
	*/

	const Engine = Matter.Engine,
		Render = Matter.Render,
		Runner = Matter.Runner,
		Composite = Matter.Composite,
		Bodies = Matter.Bodies,
		Body = Matter.Body;

	// create engine
	const engine = Engine.create(),
		world = engine.world;

	engine.gravity.y = -0.15;

	// create renderer
	const render = Render.create({
		element: element,
		engine: engine,
		options: {
			width: window.innerWidth,
			height: window.innerHeight,
			background: 'transparent',
			pixelRatio: 'auto',
			wireframes: false,
		},
	});

	Render.run(render);

	// create runner
	var runner = Runner.create();
	Runner.run(runner, engine);

	const renderOptions = {
		fillStyle: 'transparent',
	};

	const wallWidth = 10;

	let ceiling = Bodies.rectangle(w / 2, -wallWidth / 2, w * 5, wallWidth, {
		label: 'ceiling',
		isStatic: true,
		render: renderOptions,
	});

	let leftWall = Bodies.rectangle(
		0 - wallWidth / 2,
		h / 2,
		wallWidth,
		h * 5,
		{
			label: 'left',
			isStatic: true,
			render: renderOptions,
		}
	);

	let rightWall = Bodies.rectangle(
		w + wallWidth / 2,
		h / 2,
		wallWidth,
		h * 5,
		{
			label: 'right',
			isStatic: true,
			render: renderOptions,
		}
	);

	// add bodies
	Composite.add(world, [ceiling, leftWall, rightWall]);

	// fit the render viewport to the scene
	Render.lookAt(render, {
		min: { x: 0, y: 0 },
		max: { x: w, y: h },
	});

	const addRemove = () => {
		emitters.forEach((emitter) => {
			const properties = emitter();

			Composite.add(world, Bodies.circle(...properties));

			if (world.bodies.length > max) {
				Composite.remove(world, world.bodies[3]);
			}
		});

		setTimeout(addRemove, delay);
	};

	addRemove();

	// Resize
	window.addEventListener('resize', () => {
		const w = window.innerWidth;
		const h = window.innerHeight;

		render.bounds.max.x = w;
		render.bounds.max.y = h;
		render.options.width = w;
		render.options.height = h;
		render.canvas.width = w;
		render.canvas.height = h;

		Render.setPixelRatio(render, window.devicePixelRatio);

		Render.lookAt(render, {
			min: { x: 0, y: 0 },
			max: { x: w, y: h },
		});

		// Resets wall

		Body.setPosition(ceiling, { x: w / 2, y: -wallWidth / 2 });
		Body.setPosition(leftWall, { x: 0 - wallWidth / 2, y: h / 2 });
		Body.setPosition(rightWall, { x: w + wallWidth / 2, y: h / 2 });
	});

	return {
		engine: engine,
		runner: runner,
		render: render,
		canvas: render.canvas,
		stop: function () {
			Matter.Render.stop(render);
			Matter.Runner.stop(runner);
		},
	};
}
