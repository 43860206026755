<script>
	import Icon from '../shared/Icon.svelte';

	import Hero from './Hero.svelte';
	import Scene from './Scene.svelte';

	import Cover from '../custom/Cover.svelte';

	import { onMount } from 'svelte';

	import { handleIntersections } from '../../intersection.js';
	import { content, selected, theme } from '../../stores.js';

	import { getCurrentClass } from '../../js/helpers.js';
	import { updateTheme } from '../../js/theme.js';

	export let section;

	let node;

	$: classes = section.style.join(' ');
	$: currentClass = getCurrentClass(
		$selected.index,
		section.intersectionIndex
	);

	onMount(() => {
		/*
		const options = {
			threshold: 0.01,
		};

		const observer = new IntersectionObserver(handleIntersections, options);

		observer.observe(node);

		selected.subscribe((selected) => {
			if (selected.id === section.intersectionId) {
				theme.set(section.theme);
			}
		});
		*/
	});
</script>

<section
	bind:this={node}
	id="section-{section.id}"
	data-intersection-id={section.intersectionId}
	data-intersection-index={section.intersectionIndex}
	data-intersection-section={section.label}
	class="section {classes} {currentClass}"
>
	{#if section.title}
		<div class="section__title">
			<div />

			<div>
				{#if section.title.section}
					<h3 class="section__title__section">
						{section.title.section}
					</h3>
				{/if}

				{#if section.title.headline}
					<h1 class="section__title__headline">
						{section.title.headline}
					</h1>
				{/if}

				{#if section.title.subheadline}
					<h2 class="section__title__subheadline">
						{section.title.subheadline}
					</h2>
				{/if}
			</div>

			<div class="scroll-for-more">
				<span class="inline-icon">
					<Icon icon="down-arrow" />
				</span>

				Scroll for more
			</div>
		</div>
	{/if}

	{#if section.hero && section.hero.type === 'Cover'}
		<Cover />
	{/if}

	{#if section.scenes.length}
		<div class="section__heros">
			{#each section.scenes as s, index}
				<Hero scene={s} />
			{/each}
		</div>
	{/if}

	{#each section.scenes as s, index}
		<Scene {section} scene={s} />
	{/each}
</section>

<style>
	.section__title {
			justify-content: space-between;
		}

		.scroll-for-more {
			display: flex;
			align-items: center;

			letter-spacing: 0.05em;
			text-transform: uppercase;

			color: var(--gray-200);
		}

		.inline-icon {
			display: inline-block;

			margin: 0 8px 0 0;

			width: 18px;
			height: 18px;
		}

		@media screen and (max-width: 720px) {
			.section__title h1 {
				max-width: 300px;

				font-size: 48px;
			}

			.section__title h2 {
				max-width: 300px;

				font-size: 16px;
				line-height: 1.5;
			}
		}
</style>
