<script>
	import { onMount } from 'svelte';

	import { processData } from './js/data.js';
	import { getParams, navigateTo } from './js/helpers.js';
	import { updateTheme } from './js/theme.js';

	import Header from './components/header/Header.svelte';
	import Navigation from './components/Navigation.svelte';

	import Debug from './components/scrolly/Debug.svelte';

	import Section from './components/scrolly/Section.svelte';

	import { content, selected, showBackground, theme } from './stores.js';

	import Bar from './components/custom/Bar.svelte';
	import Chart from './components/custom/Chart.svelte';
	import Cover from './components/custom/Cover.svelte';
	import Gases from './components/custom/Gases.svelte';
	import Intentional from './components/custom/Intentional.svelte';
	import Malfunctioning from './components/custom/Malfunctioning.svelte';
	import Test from './components/custom/Test.svelte';

	import Footer from './components/footer/Footer.svelte';

	// For prerendering, this must be a JS import

	import { sections } from '../public/client/content/content.js';

	let debug = false;

	content.set(processData(sections));

	onMount(() => {
		// Jump to section

		function navigate() {
			const id = window.location.hash.replace(/^#![\/]?/, '');
			const target = document.getElementById(id);

			if (!target) return;

			target.scrollIntoView({ behavior: 'smooth' });

			// navigateTo(hash);
		}

		navigate();

		window.addEventListener('hashchange', navigate);

		// Update themes

		updateTheme($theme);

		theme.subscribe(updateTheme);

		//

		const params = getParams();

		if (params.debug) debug = true;
		if (params.content) load(params.content);

		function load(id) {
			fetch(`./client/content/${id}.json`)
				.then((response) => response.json())
				.then((json) => {
					content.set(processData(json));
					theme.set(null);
				});
		}
	});
</script>

{#if false}
	<Gases />
{:else}
	{#if debug}
		<Debug />
	{/if}

	<Header />

	<Navigation />

	<div
		class="sections {!$showBackground
			? 'hideBackground'
			: 'showBackground'}"
	>
		{#each $content as s, index}
			<Section section={s} />
		{/each}
	</div>

	<Footer />
{/if}
