<script>
	import { content, selected, showBackground } from '../stores.js';

	const scenes = $content[0]?.scenes.map((scene) => {
		return scene.intersectionId;
	});

	$: selectedId = `${$selected.section}/${$selected.scene}`;

	const jumpTo = (id) => {
		return () => {
			const element = document.querySelector(
				`[data-intersection-id="${id}"]`
			);

			if (!element) return;

			element.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		};
	};
</script>

<nav>
	{#each scenes as scene, index}
		<div
			class="nav__scene {selectedId === scene ? 'selected' : ''}"
			on:click={jumpTo(scene)}
		/>
	{/each}
</nav>

<style>
	nav {
		position: fixed;
		z-index: 9999;
		top: 25%;
		bottom: 25%;
		left: 24px;

		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.nav__scene {
		width: 10px;
		height: 10px;
		margin: 0 0 8px 0;

		cursor: pointer;
		transition: background 0.2s;

		border: 1px solid #fff;
		border-radius: 12px;
	}

	.nav__scene.selected {
		background: #fff;
	}

	@media screen and (max-width: 960px) {
		nav {
			display: none;
		}
	}
</style>
