<script>
	import { onMount } from 'svelte';

	import { faq } from './faq.js';

	import Icon from '../shared/Icon.svelte';

	import Comparison from './Comparison.svelte';
	import Question from './Question.svelte';

	let questions = faq;

	let links = [
		{
			label: 'Facebook',
			url: 'https://www.facebook.com/RockyMtnInst/',
			logo: 'FB',
		},
		{
			label: 'LinkedIn',
			url: 'https://www.linkedin.com/company/rocky-mountain-institute',
			logo: 'linkedin',
		},
		{
			label: 'Twitter',
			url: 'https://twitter.com/RockyMtnInst',
			logo: 'Twitter',
		},
	];

	onMount(() => {});
</script>

<div class="footer">
	<div class="footer__content">
		<!-- <section id="research">
			<h2>Our Research</h2>

			<p>Learn more with our published study.</p>
		</section> -->

		<!-- <section id="comparison">
			<h2>US Gas vs.Coal Comparison</h2>
			<p class="comparison__header">Leakage rate</p>

			<Comparison />

		</section> -->

		<section id="faq">
			<h2>FAQs</h2>

			<div class="questions">
				{#each faq as question}
					<Question {question} />
				{/each}
			</div>
		</section>

		<section id="about">
			<h2>Download</h2>

			<p>
				To download all charts <a href="/downloads/charts.zip">click here</a>.
			</p>
		</section>

		<section id="notes">
			<h2>Note</h2>

			<p>
				Calculations assume a 20-year global warming potential for methane.
			</p>
		</section>

		<section id="about">
			<h2>About Us</h2>

			<p>Coal vs. Natural Gas is a project of <a href='https://rmi.org'>RMI</a></p>
		</section>
	</div>

	<div class="links">
		<div class="links__logo">
			<a href="https://rmi.org" target="_blank">
				<img src="./client/logo/rmi_logo.svg" alt="RMI" />
			</a>
		</div>

		<div class="links__social">
			{#each links as link}
				<a href={link.url} target="_blank">
					<div class="links__social__icon">
						<Icon icon={link.logo} color="white" />
					</div>
				</a>
			{/each}
		</div>
	</div>
	
	<p style="padding-left: 24px;">
		<small>2023 RMI | <a href="https://rmi.org/privacy-policy/"
			>Privacy Policy</a
		>
		</small>
	</p>
</div>

<style>
	.footer__content {
		max-width: 960px;
		margin: 120px auto;
	}

	.footer__content section {
		margin: 0 24px 60px 24px;
	}

	.comparison__header {
		text-align: right;
		letter-spacing: 0.05em;
		text-transform: uppercase;

		color: rgba(144, 144, 144, 1);

		font-weight: 600;
	}

	.links {
		display: flex;
		justify-content: space-between;

		padding: 24px 24px 36px 24px;

		border-top: 1px solid var(--secondary);
	}

	.links__logo img {
		height: 100%;

		object-fit: contain;
	}

	.links a {
		margin: 0 4px;

		font-weight: 600;
	}

	.links__social {
		display: flex;
	}

	.links__social__icon {
		width: 32px;
		height: 32px;
		padding: 8px;

		border-radius: 50%;
		background: rgba(255, 255, 255, 0.2);

		line-height: 18px;
	}

</style>
