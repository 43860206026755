<script>
	export let color = 'curretColor	';
	export let icon = 'left_arrow';
</script>

<div>
	<svg viewBox="0 0 100 100" class="" {color}>
		<use xlink:href="./client/media/icons.svg#{icon}" />
	</svg>
</div>

<style>
</style>
