<script>
	import { onMount } from 'svelte';

	import Icon from '../shared/Icon.svelte';

	export let question;

	let opened = false;

	onMount(() => {});
</script>

<div class="question" on:click={() => (opened = !opened)}>
	<div>
		{question.question}
	</div>

	<div class="question__icon">
		<Icon icon={opened ? 'collapse' : 'expand'} color="#fff" />
	</div>
</div>

<div class="answer {opened ? 'opened' : 'closed'}">
	{@html question.answer}
</div>

<style>
	.question {
		display: flex;
		justify-content: space-between;

		padding: 24px 0;

		cursor: pointer;
		user-select: none;

		color: var(--secondary);
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);

		font-size: 20px;
		font-weight: 900;
		line-height: 1.2;
	}

	.question__icon {
		flex-grow: 0;
		flex-shrink: 0;

		width: 24px;
		height: 24px;
		margin: 0 0 0 8px;
	}

	.answer {
		padding: 12px 12px 0;

		transition: height 0.5s;

		font-size: 20px;
		font-weight: 400;
	}

	.answer.closed {
		display: none;
	}

	:global(.answer figure) {
		position: relative;

		margin: 0;
		padding: 16px 0;

		border-top: 1px solid rgba(255, 255, 255, 0.2);
	}

	:global(.answer h2) {
		margin: 0 0 16px 0;

		font-size: 24px;
	}

	:global(.answer figure .caption) {
		font-family: 'Lora';
		font-size: 14px;
		line-height: 1.5;
	}

	:global(.answer figure .caption p) {
		margin: 0;
	}

	:global(.answer figure img) {
		width: 100%;
	}

	@media screen and (max-width: 720px) {
		.answer {
			padding: 24px 0;
		}
	}
</style>
