<script>
	import { onMount } from 'svelte';

	import Icon from '../shared/Icon.svelte';
	import Menu from './Menu.svelte';

	let selectedMenu = null;

	window.addEventListener('click', (event) => {
		selectedMenu = null;
	});

	const setMenu = (selection) => {
		return (event) => {
			event.stopPropagation();
			
			selectedMenu = selectedMenu !== selection ? selection : null;
		};
	};

	const scrollTo = (id) => {
		return () => {
			const element = document.getElementById(id);
			element.scrollIntoView({ behavior: 'smooth' });
		};
	};

	const description = encodeURI(
		'Newly published research compares the climate impact between natural gas and coal.'
	);
	const url = encodeURI('coalvsnaturalgas.org');

	const socialLinks = [
		{
			label: 'Facebook',
			url: `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${description}`,
			logo: 'FB',
		},
		{
			label: 'LinkedIn',
			url: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
			logo: 'linkedin',
		},
		{
			label: 'Twitter',
			url: `https://twitter.com/intent/tweet?text=${description}&url=${url}`,
			logo: 'Twitter',
		},
	];

	const navigationLinks = [
		{
			label: 'About Us',
			id: 'about',
		},
		/*
		{
			label: 'Our Research',
			id: 'research',
		},
		*/
		{
			label: 'Comparison Chart',
			id: 'comparison',
		},
		{
			label: 'FAQ',
			id: 'faq',
		},
	];
</script>

<header class="header">
	<div class="header__logo"></div>

	<div class="menus">
		<div class="menu" on:click={setMenu('social')}>
			<div style="padding: 4px">
				<Icon icon="share" color="white" />
			</div>

			<div
				class="menu__items menu__items--{selectedMenu === 'social'
					? 'opened'
					: 'closed'}"
			>
				{#each socialLinks as link}
					<a class="menu__item" href={link.url} target="_blank">
						<span class="menu__item__icon">
							<Icon icon={link.logo} color="white" />
						</span>

						{link.label}
					</a>
				{/each}
			</div>
		</div>

		<div class="menu" on:click={setMenu('navigation')}>
			<div style="padding: 4px">
				<Icon icon="menu" color="white" />
			</div>

			<div
				class="menu__items menu__items--{selectedMenu === 'navigation'
					? 'opened'
					: 'closed'}"
			>
				{#each navigationLinks as link}
					<div class="menu__item" on:click={scrollTo(link.id)}>
						{link.label}
					</div>
				{/each}
			</div>
		</div>
	</div>
</header>

<style>
	.header {
		position: fixed;
		z-index: 9999;
		top: -12px;
		right: 0;
		left: 0;

		display: flex;
		align-items: center;
		justify-content: space-between;

		height: 72px;
		padding: 12px 24px 0 24px;
	}

	.header__logo {
		text-transform: uppercase;
	}

	.menus {
		display: flex;
	}

	.menu {
		position: relative;
        cursor: pointer;
		width: 36px;
		height: 36px;
		margin: 0 6px;

		border-radius: 18px;
		/* background: var(--primary); */
	}

	.menu__items {
		position: absolute;
		top: calc(100% + 6px);
		right: 0;

		overflow: hidden;

		width: 240px;

		border-radius: 8px;
		background: var(--teal-600);

		font-size: 18px;
		font-weight: 500;
	}

	.menu__items--closed {
		display: none;
	}

	.menu__items--opened {
		display: block;
	}

	.menu__items > * {
		display: flex;
		align-items: center;

		height: 48px;
		padding: 6px 12px;

		text-decoration: none;
	}

	.menu__items > *:not(:last-child) {
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}

	.menu__item {
		display: flex;
		align-items: center;
        cursor: pointer;
		transition: background 0.5s;
	}

	.menu__item__icon {
		width: 24px;
		height: 24px;
		margin: 0 6px 0 0;
		padding: 4px;

		border-radius: 50%;
		background: rgba(255, 255, 255, 0.2);

		line-height: 18px;
	}

	.social__links {
		display: flex;
	}

	.social__links a {
		margin: 0 2px;
	}

	@media (hover: hover) {
		.menu:hover {
			background: rgba(255, 255, 255, 0.2);
		}

		.menu__item:hover {
			background: var(--secondary);
		}
	}
</style>
