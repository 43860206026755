<script>
	import { onMount } from 'svelte';

	import gravity from './Leaks.js';

	import { renderStyles } from './shared.js';

	export let data;
	export let settings;

	let element;
	let width = window.innerWidth;
	let height = window.innerHeight;

	let mobile = window.innerWidth < 720;

	const emitters = [0.325, 0.625].map((offset) => {
		return () => {
			const type = Math.random() < 0.9 ? 'carbon' : 'methane';

			let yOffset = mobile ? 32 : 72;

			const x = window.innerWidth * offset + Math.random() * 24;
			const y = window.innerHeight / 2 - yOffset + Math.random() * 30;

			const scale = (v) => Math.sqrt(v / Math.PI) * 3;
			const size = {
				carbon: scale(1),
				methane: scale(80),
			};

			const radius = size[type];

			const options = {
				render: renderStyles[type].options,
			};

			return [x, y, radius, options];
		};
	});

	onMount(() => {
		gravity(element, {
			width,
			height,

			delay: 250,
			emitters,
			max: 50,
		});
	});
</script>

<svelte:window bind:innerHeight={height} bind:innerWidth={width} />

<div class="figure">
	<div class="figure__header">
		<h2>Intentional Methane Releases</h2>
	</div>

	<div class="figure__bubbles" bind:this={element} style="" />

	<div class="illustrations" style="display: flex">
		<div class="illustration">
			<img
				src="./client/media/images/illustrations/Wellhead.svg"
				alt=""
			/>
			<div class="figure__label">Venting</div>
			<p class="figure__text">
				Methane can only be visibly detected by imaging or high-end
				technological equipment.
			</p>
		</div>
		<div class="illustration">
			<img
				src="./client/media/images/illustrations/Wellhead.svg"
				alt=""
			/>
			<div class="figure__label">Flaring</div>
			<p class="figure__text">
				Inefficient flaring releases methane when gas is burned and converted to less potent CO<sub>2</sub>.
			</p>
		</div>
	</div>

	<!-- <div
		class="figure__background"
		style="background-image: url('./client/media/images/intentional.svg')"
	/> -->
</div>

<style>
	.figure {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		pointer-events: none;
	}

	/*

	.figure__header {
		position: absolute;

		width: 100%;
		padding: 60px;
	}

	.figure h2 {
		max-width: 1280px;
		margin: auto;
		padding: 60px 0;
	}

	*/

	.figure__bubbles {
		position: absolute;

		overflow: hidden;

		width: 100%;
		height: 100%;
	}

	.illustrations {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 100%;
		padding: 180px 60px 0 60px;
	}

	.illustration {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: flex-end;

		width: calc(100% / 3);
		height: 240px;
		padding: 0 12px;
	}

	.illustration img {
		width: 100%;
		max-width: 240px;
		margin: 0 0 24px 0;

		object-fit: contain;
	}

	.illustration__label {
		text-transform: uppercase;
	}

	.figure__background {
		position: absolute;

		overflow: hidden;

		width: 100%;
		height: 100%;

		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	@media screen and (max-width: 720px) {
		.illustrations {
			padding: 40vh 24px 0 24px;
		}

		.illustration {
			width: 50%;
		}
	}
</style>
